export class Reports {
  public userName: string;
  public projectManager: string;
  public recruiter: string;
  public jobName: string;
  public jobNumber: string;
  public clientId: number;
  public startDate: string;
  public endDate: string;
  public points: string;
  public pointsMismatch: boolean;
  public memberStatus: string;
  public eventType: string;
  public memberIds: string;
  public jobId: number;
  public jobStatus: string;
  public invoiceStatus: string;
  public clientName: string;
  public approvedDate: string;
  public detail: string;
  public role: string;
  public position: string;
  public managers: string;
  public managerRate: string;
  public managerHrs: string;
  public assistantPM: string;
  public assistantPMRate: string;
  public assistantPMHrs: string;
  public recruiters: string;
  public recruitersRate: string;
  public recruitersHrs: string;
  public amountQuoted: string;
  public amountInvoiced: string;
  public amountSpent: string;
  public profitorLoss: string;

  public dateJobtApproved: string;
  public Incentivedesc: string;
  //Inv_Quoted_Participants 
  // Inv_Actual_Participants 
  // Total_Inv_Quoted_Amt 
  // Total_Inv_Actual_Amt 
  // Total_Amount_Paid 
  // Qualified_Participants 
  // Qualified_Participants_Paid 
  // Participants_Not_Paid
  // Total_Not_Paid_Amount
  // Number_overPaid 




}
